body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "FreeMono";
  src: local("FreeMono"),
   url("./fonts/FreeMono.ttf") format("truetype");
}

@font-face {
  font-family: "FreeMonoBoldOblique";
  src: local("FreeMonoBoldOblique"),
   url("./fonts/FreeMonoBoldOblique.ttf") format("truetype");
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: "FreeMonoOblique";
  src: local("FreeMonoOblique"),
   url("./fonts/FreeMonoOblique.ttf") format("truetype");
  font-style: oblique;
}

@font-face {
  font-family: "FreeSans";
  src: local("FreeSans"),
   url("./fonts/FreeSans.ttf") format("truetype");
}

@font-face {
  font-family: "FreeSansBold";
  src: local("FreeSansBold"),
   url("./fonts/FreeSansBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FreeSansBoldOblique";
  src: local("FreeSansBoldOblique"),
   url("./fonts/FreeSansBoldOblique.ttf") format("truetype");
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: "FreeSansOblique";
  src: local("FreeSansOblique"),
   url("./fonts/FreeSansOblique.ttf") format("truetype");
  font-style: oblique;
}

@font-face {
  font-family: "FreeSerif";
  src: local("FreeSerif"),
   url("./fonts/FreeSerif.ttf") format("truetype");
}

@font-face {
  font-family: "FreeSerifBold";
  src: local("FreeSerifBold"),
   url("./fonts/FreeSerifBold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "FreeSerifBoldItalic";
  src: local("FreeSerifBoldItalic"),
   url("./fonts/FreeSerifBoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: "FreeSerifItalic";
  src: local("FreeSerifItalic"),
   url("./fonts/FreeSerifItalic.ttf") format("truetype");
  font-style: italic;
}

.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.editor-placeholder {
  color: #999;
  overflow: hidden;
  position: absolute;
  top: 15px;
  left: 15px;
  user-select: none;
  pointer-events: none;
}

.editor-paragraph {
  margin: 0 0 15px 0;
  position: relative;
}