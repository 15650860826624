.image-gallery {
  padding: 16px;
}

.item-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.thumbnail-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 128px;
  background-color: #f0f0f0;
}

.thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  transition: border 0.2s;
}

.image-gallery img {
  width: 128px;
  height: 128px;
}

.image-gallery .grid-container {
  display: flex;
  flex-wrap: wrap;
  margin: -1px; /* Adjust the negative margin to half of the spacing */
}

.image-gallery .grid-item {
  box-sizing: border-box;
  padding: 2px; /* Adjust the padding to match the desired spacing */
}

.title-text {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}

.thumbnail.selected {
  border: 3px solid red;
}

.thumbnail:hover {
  border: 3px solid red;
}

